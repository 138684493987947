/*--------------------------------------------------------------
# About Me
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #3b434a;
  text-transform: uppercase;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .image {
  background: url("../../../public/assets/img/portfolio/Foto-23.jpeg") center no-repeat;
  background-size: cover;
  min-height: 500px;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .feature-box {
  padding: 5px 5px;
  box-shadow: 0px 0 30px rgba(112, 1, 69, 0.08);
  transition: 0.3s;
  height: 100%;
}

.features .feature-box p {
  margin-bottom: 0;
}

.features .feature-box h3 {
  font-size: 16px;
  color: #b33f62;
  font-weight: 700;
  margin: 0;
}

.features .feature-box i {
  line-height: 0;
  background: #ecf3ff;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.features .feature-box:hover i {
  background: #590d22;
  color: #fff;
}
