/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: url("../../../public/assets/img/portfolio/Foto-22.jpeg") fixed no-repeat center top;
    background-size: cover;
    color: #fff;
    font-size: 14px;
    text-align: center;
    padding: 80px 0;
    position: relative;
  }
  
  #footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  
  #footer .container {
    position: relative;
  }
  
  #footer h3 {
    font-size: 36px;
    font-weight: 700;
    color: #b33f62;
    position: relative;
    font-family: "Satisfy", serif;
    padding: 0;
    margin: 0 0 15px 0;
  }
  
  #footer p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }
  
  #footer .social-links {
    margin: 0 0 40px 0;
  }
  
  #footer .copyright {
    margin: 0 0 5px 0;
  }
  
  @media (max-width: 991px) {
    #footer {
       background-attachment: unset;
       background-position: unset;
       background-repeat: no-repeat;
       background-size: cover;
       height: 100%;
       width: 100%;
    }
  }