/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 100vh;
    background: url("../../../public/assets/img/portfolio/Foto-14.jpeg") center;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
  }
  
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-family: "Satisfy", cursive;
    color: #b33f62;
  }
  
  #hero h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  #hero .btn-scroll {
    transition: 0.4s;
    color: rgba(255, 255, 255, 0.6);
    animation: up-down 1s ease-in-out infinite alternate-reverse both;
  }
  
  #hero .btn-scroll i {
    font-size: 48px;
  }
  
  #hero .btn-scroll:hover {
    color: #b33f62;
  }
  
  #hero audio::-webkit-media-controls-timeline {
    display: none;
  }
  #hero audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  #hero audio::-webkit-media-controls-current-time-display {
    display: none;
  }
  
  #hero .audio {
    position: absolute; 
    bottom: 0px; 
    width: 180px; 
    height: 15vh; 
    right: 40px;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 575px) {
    #hero h1 {
      font-size: 60px;
    }
  
    #hero h2 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  
  @media (max-width: 991px) {
    #hero {
      height: 60vh;
       width: 100%;
       background-size: cover;
    }
    
    #hero audio::-webkit-media-controls {
      display: none;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(5px);
    }
  
    100% {
      transform: translateY(-5px);
    }
  }
  
  #hero .countdown {
    margin-bottom: 80px;
  }
  
  #hero .countdown div {
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    margin: 10px 10px;
    width: 100px;
    padding: 15px 0;
  }
  
  #hero .countdown div h3 {
    color: #b33f62;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 15px;
  }
  
  #hero .countdown div h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  
  @media (max-width: 575px) {
    #hero .countdown div {
      width: 70px;
      padding: 10px 0;
      margin: 10px 8px;
    }
  
    #hero .countdown div h3 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  
    #hero .countdown div h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }