/*--------------------------------------------------------------
# My Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
    list-style: none;
    margin-bottom: 20px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    margin: 0 10px 10px 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    padding: 7px 10px;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.3s ease-in-out;
    border: 2px solid #fff;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #b33f62;
    border-color: #b33f62;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-img {
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-img img {
    transition: all 0.8s ease-in-out;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 3;
    right: 15px;
    transition: all ease-in-out 0.3s;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #fff;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #ffc041;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-img img {
    transform: scale(1.2);
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
  }
  