/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
  .clients .swiper-wrapper {
    align-items: center;
  }
  .clients .swiper-slide {
    width: 86px;
    margin-right: 120px;
  }

  .clients .swiper-slide img {
    opacity: 0.5;
    height: auto;
    transition: 0.3s;
    max-width: 100%;
  }
  
  .clients .swiper-slide img:hover {
    opacity: 1;
  }
  
  .clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #b33f62;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #b33f62;
  }