/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
    padding: 80px 0;
    background: url("../../../public/assets/img/portfolio/Foto-8.jpeg") fixed no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
  }
  
  .testimonials::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(14, 16, 17, 0.7);
  }
  
  .testimonials .section-header {
    margin-bottom: 40px;
  }
  
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonials .testimonial-item {
    text-align: center;
    color: #fff;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #b33f62;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: rgba(179, 63, 98, 0.4);
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
    color: #eee;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #b33f62;
  }
  
  @media (min-width: 992px) {
    .testimonials .testimonial-item p {
      width: 80%;
    }
  }