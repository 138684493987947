/*--------------------------------------------------------------
# Contact Me
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i.bx {
  font-size: 24px;
  color: #fff;
  border-radius: 50%;
  padding: 15px;
  background: #b33f62;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .social-links {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 27px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus, 
.contact .php-email-form select:focus,
.contact .php-email-form textarea:focus {
  outline: 2px solid #b33f62;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button {
  background: #b33f62;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

.contact .php-email-form button:hover {
  background: #fff0f3;
  color: #b33f62;
}

@media (min-width: 991px) {
  .contact .local {
    width: 48%;
  }
}