/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  z-index: 997;
  transition: all 0.5s;
  height: 80px;
  background: rgba(179, 63, 98, 0.8);
}


#header.header-transparent {
  background: transparent;
}

#header.header-scrolled {
  height: 60px;
  background: rgba(179, 63, 98, 0.8);
}